import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../Page";
import ActionCreator from "../ActionCreator";
import * as Icon from "../Components/Icon";
import {
  FacebookLoginButton,
  GoogleLoginButton
} from "../Components/SocialLoginButtons";

class SocialLoginPage extends Component {
  render() {
    let { navActions, appActions } = this.props;

    return (
      <Wrapper>
        <button
          className="back"
          onClick={() => {
            let localSettingUid = appActions.getLocalSettingUid();

            if (localSettingUid) {
              return navActions.push(`/redirect/?uid=${localSettingUid}`);
            }

            return navActions.push("/");
          }}
        >
          <Icon.ArrowBack color="white" style={{ marginRight: 5 }} />
          <span>BACK</span>
        </button>
        <div className="container">
          <img src="/images/nfc-logo.png" />
          <h1>LOGIN USING</h1>
          <div>
            <FacebookLoginButton css="margin-bottom: 20px; max-width: 280px; width: 100%;" />
            <GoogleLoginButton css="max-width: 280px; width: 100%;" />
          </div>
          <p>Login with your social media account.</p>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #789fd5;

  & .back {
    border: 0px;
    padding: 20px;
    cursor: pointer;
    min-width: 100px;
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: 14px;
    color: white;

    :focus {
      outline: none;
    }
  }

  & .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: calc(100% - 66px);

    & > img {
      margin-bottom: 30px;
      max-width: 250px;
      width: 100%;

      @media screen and (max-width: 414px) {
        max-width: 200px;
      }
    }

    & > h1 {
      margin-bottom: 30px;
      letter-spacing: 5px;
      font-weight: 400;
      font-size: 30px;
      color: white;
    }

    & > div {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    & > p {
      text-align: center;
      letter-spacing: 2px;
      font-size: 14px;
      color: white;
    }

    @media screen and (max-width: 320px) {
      & > img {
        margin-bottom: 15px;
      }

      & > h1 {
        margin-bottom: 15px;
      }
    }
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(SocialLoginPage)
);
